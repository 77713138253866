import React from 'react';
import { RichTextField, Filter, List, Datagrid, TextField } from 'react-admin';
import MyDateTime from './mydatetime';

const ChatQueriesFilter = (props) => (
    <Filter {...props}>
        
    </Filter >
);

export const ChatQueriesList = (props) => {
    return (
        <List {...props}>
                <Datagrid>
                    <TextField label="IP Address" source="id" />
                    {/* <TextField label="Date Time" source="DTTM" /> */}
                    <MyDateTime label="Date Time" source="DTTM"/>
                    <TextField label="Query" source="Response.query" />
                    <RichTextField label="Response" source="Response.result" />
                </Datagrid>
        </List>
    );
};


