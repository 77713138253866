import React from 'react';
import { BooleanInput, FormDataConsumer, Filter, BulkDeleteButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import ExtraHrsButton from './ExtraHrsButton';
import { useMediaQuery } from '@material-ui/core';
import CreateFeeReminderEmailButton from './CreateFeeReminderEmailButton';
import MyImage from './myimage.js';
import Typography from '@material-ui/core/Typography';
import { getYears } from './utils';


const StudentSigninoutFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Month" validate={required()} source="Month" allowEmpty={false} choices={[
            { id: '0', name: 'Jan' },
            { id: '1', name: 'Feb' },
            { id: '2', name: 'Mar' },
            { id: '3', name: 'Apr' },
            { id: '4', name: 'May' },
            { id: '5', name: 'Jun' },
            { id: '6', name: 'Jul' },
            { id: '7', name: 'Aug' },
            { id: '8', name: 'Sep' },
            { id: '9', name: 'Oct' },
            { id: '10', name: 'Nov' },
            { id: '11', name: 'Dec' },
        ]} />
        <SelectInput alwaysOn label="Year" validate={required()} source="Year" allowEmpty={false} choices={getYears()} />
        <SelectInput alwaysOn label="GetSignatures" validate={required()} source="Signatures" allowEmpty={false} choices={[
            { id: 'N', name: 'N' },
            { id: 'Y', name: 'Y' },
        ]} />
        <BooleanInput alwaysOn source="includeDisenroll" label="Include Disenrolled" />
        <FormDataConsumer alwaysOn>
        {({ formData, ...rest }) => (
        <ReferenceInput alwaysOn label="PIN" source="id" reference="Students" filter={formData.includeDisenroll ? { FEStatus: "Active:Disenrolled" } : {FEStatus: "Active"}} sort={{ field: 'Sname', order: 'ASC' }} {...rest}>
            <SelectInput optionText={(record) => { let val = record.PIN + "-" + record.Sname; return val; }} />
        </ReferenceInput>
        )}
        </FormDataConsumer>
        <TextInput alwaysOn label="Date" source="siodate" ></TextInput>
    </Filter>
);


var d = new Date();
var dt = ('0' + (d.getMonth() + 1)).slice(-2) + '/'
    + ('0' + d.getDate()).slice(-2) + '/'
    + d.getFullYear();
//console.log("dateeeee", dt.substr(0, dt.indexOf('/')).length, dt)
var mnth = d.getMonth();
var yr = d.getFullYear();
//console.log(mnth)

var cutofftm = new Date();
cutofftm.setHours(8,31,0);

function calTardy(rec) {
    var intm = new Date();
        var time = rec.Time.match(/(\d+)(?::(\d\d))?\s*(p?)/);
        intm.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
        intm.setMinutes(parseInt(time[2]) || 0);
        if (rec.Status == "IN" && intm > cutofftm) {
            return true;
        } else {
            return false;
        }
}
const Aside = ({ data, ids }) => {
    // //console.log(data, ids); 
    var ins = ids.map(id => data[id]).reduce((ins, rec) => {
        if (rec.Status == "IN") {
            return ins + 1
        } else {
            return ins;
        }
    }, 0);
    var outs = ids.map(id => data[id]).reduce((outs, rec) => {
        if (rec.Status == "OUT") {
            return outs + 1
        } else {
            return outs;
        }
    }, 0);
    var tardys = ids.map(id => data[id]).reduce((tardys, rec) => {
        if (calTardy(rec)) {
            return tardys + 1
        } else {
            return tardys
        }
        // var intm = new Date();
        // var time = rec.Time.match(/(\d+)(?::(\d\d))?\s*(p?)/);
        // intm.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
        // intm.setMinutes(parseInt(time[2]) || 0);
        // if (rec.Status == "IN" && intm > cutofftm) {
        //     return tardys + 1
        // } else {
        //     return tardys;
        // }
    }, 0);
    return (
        <div style={{ width: 200, margin: '1em' }} >
            <Typography >Extra Hours Total</Typography>
            <Typography variant="body1">
                {ids.map(id => data[id]).reduce((sum, rec) => {
                    if (rec.EHrs.ExtraAmount) {
                        return sum + parseFloat(rec.EHrs.ExtraAmount);
                    } else {
                        return sum;
                    }
                }, 0.0)}
                <br />
                <Typography >Signed IN</Typography>
                {ins}
                <br />
                <Typography >Signed OUT</Typography>
                {outs}
                <Typography >In School</Typography>
                {ins - outs}
                <Typography >Tardys</Typography>
                {tardys}
                <Typography >Tardy %</Typography>
                {((tardys/ins)*100).toFixed(2)}
            </Typography>
        </div >
    );
}
const rowStyle = (record, index) => ({
    backgroundColor: calTardy(record) ? 'red' : 'white',
});

export const StudentSigninoutList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    var isOfficeMgr = false;
    if (permissions !== undefined && (permissions.role === 'officemgr')) {
        isOfficeMgr = true;
    }
    return (
        <List aside={<Aside />} filters={<StudentSigninoutFilter />} filterDefaultValues={{ Month: mnth, Year: yr, siodate: dt, Signatures: "N" }} bulkActionButtons={isOfficeMgr ? <OfficeMgrListActions {...props} /> : <StudentSigninoutListActions {...props} />} {...props}>
            {isSmall ? (
                <Datagrid rowStyle={rowStyle} >
                    <TextField label="Date" source="SIODate" />
                    <TextField label="Student Name" source="Name" />
                    <TextField label="SignIn Type" source="Status" />
                    <TextField label="Time" source="Time" />
                    <TextField label="Parent Name" source="ParentName" />
                    <MyImage label="Signature" />
                    <TextField label="Notes" source="Notes" />
                    <EditButton />
                </Datagrid>
            ) : (
                <Datagrid rowStyle={rowStyle} {...props}>
                    <TextField label="Date" source="SIODate" />
                    <TextField label="Student Name" source="Name" />
                    <TextField label="SignIn Type" source="Status" />
                    <TextField label="Time" source="Time" />
                    <TextField label="Extra Time(in Hrs)" source="EHrs.ExtraTime" />
                    <TextField label="Extra Amount(in $)    " source="EHrs.ExtraAmount" />
                    <TextField label="Parent Name" source="ParentName" />
                    <MyImage label="Signature" />
                    <TextField label="Notes" source="Notes" />
                    <EditButton />
                </Datagrid>)}
        </List>
    );
};

const StudentSigninoutTitle = ({ record }) => {
    return <span>Signinout {record ? `"${record.id}"` : ''}</span>;
};

export const StudentSigninoutEdit = (props) => (
    <Edit title={<StudentSigninoutTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput disabled label="Date" source="SIODate" />
            <TextInput disabled label="Student Name" source="Name" />
            <SelectInput label="SignIn Type" source="Status" choices={[
                { id: 'IN', name: 'IN' },
                { id: 'OUT', name: 'OUT' },
            ]} />
            <TextInput label="Time" source="Time" />
            <MyImage disabled label="Signature" />
            <TextInput label="Notes" source="Notes" />
        </SimpleForm>
    </Edit>
);

const StudentSigninoutListActions = props => (
    <TopToolbar>
        <BulkDeleteButton {...props} undoable="true" />
    </TopToolbar>
);

const OfficeMgrListActions = props => (
    <TopToolbar>
    </TopToolbar>
);